<template>
  <div class="shipments-page">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$t('Search')}}</h2>
      </div>
    </div>
    <div class="clearfix w-full">
      <print-label-button :selected="selected" class="mr-4 float-left whitespace-nowrap"></print-label-button>
    </div>
    <div class="mb-base">
      <vs-input icon-pack="feather" icon="icon-search" :placeholder="$t('Enter tracking number or phone number')" 
        v-model="searchVal" class="w-full is-label-placeholder" 
        @keyup.enter.native="[searchVal? handleSearch(searchVal): '']"/>
    </div>
    <shipblu-table
      class="mb-base"
      :sst="true"
      :data="shipments"
      orders
      multiple
      v-model="selected"
      :tableLoader="tableLoader"
    >

      <template slot="thead">
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Customer Name')}}</shipblu-th>
        <shipblu-th>{{$t('Customer Address')}}</shipblu-th>
        <shipblu-th>{{$t('Packages')}}</shipblu-th>
        <shipblu-th>{{$t('Package Size')}}</shipblu-th>
        <shipblu-th>{{$t('Pickup Date')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Scheduled Delivery Date')}}</shipblu-th>
        <shipblu-th>{{$t('Delivered On')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
          :to="{ name: 'manager-search-order-view', params: { type: data[indextr].tracking_number[0] === '4' ? 'cash-collections' : data[indextr].tracking_number[0] === '2' || (data[indextr].tracking_number[0] === '3' && data[indextr].tracking_number[1] === '2') ? 'returns' : 'delivery-orders', orderID: data[indextr].id }}">
          <shipblu-td :data="data[indextr].createdOn">
            {{ common.splitYearOfDate(data[indextr].created) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].trackingNumber" class="inactive-link">
            <p v-on:click.stop>{{ data[indextr].tracking_number }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].merchant">
            {{ data[indextr].merchant.name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].customerInfo">
            {{ data[indextr].customer.full_name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].addressLine">
            {{ data[indextr].customer.address.line_1 }} / {{ data[indextr].customer.address.line_2 }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].packages">
            {{ data[indextr].packages ? data[indextr].packages.length : 0}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].packages">
            <template v-if="data[indextr].packages">
              <p v-for="packageItem in data[indextr].packages" :key="packageItem.index">
                {{packageItem.package.package_size.short_code}}
              </p>
            </template>
          </shipblu-td>

          <shipblu-td :data="data[indextr].pickupDate">
            {{ data[indextr].pickup_date ? new Date(data[indextr].pickup_date).toLocaleDateString('fr-CA') : ''}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
            <template v-if="checkTooltip(data[indextr])">
              <vx-tooltip :text="data[indextr].failure_data && data[indextr].failure_data.map(element => element.question === 'Choose reason for failed delivery' ? new Date(element.created).toLocaleDateString('fr-CA') + '\n' + element.answer  : '').filter(element => element !== '').join('\n \n')">
                <div class="m-auto flex items-center justify-center font-semibold">
                  <div><span class="status-icon mr-1"></span></div>
                  <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
                </div>
              </vx-tooltip>
            </template>
            <template v-else>
              <div class="m-auto flex items-center justify-center font-semibold">
                <div><span class="status-icon mr-1"></span></div>
                <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
              </div>
            </template>
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].scheduledDeliveryDate">
            {{ data[indextr].estimated_date}}
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].deliveredOn">
            {{ data[indextr].delivered_date && data[indextr].status === 'delivered' ? new Date(data[indextr].delivered_date).toLocaleDateString('fr-CA') : '' }}
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <print type="Delivery" :selected="selected.filter(item => item.tracking_number[0] === '4')"></print>
  </div>
</template>

<script>
import common  from '@/assets/utils/common'
import {sendRequest} from '../../http/axios/requestHelper.js'
import Print from '../merchant/components/PrintLabels.vue'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import PrintLabelButton from '../headOfFleet/components/PrintLabelButton.vue'
export default {
  data () {
    return {
      localStorage,
      common,
      selected: [],
      shipments: [],
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      tableLoader: false,
      searchVal: ''
    }
  },
  watch: {
    'searchVal' (val) {
      if (val === '') {
        this.emptyFields()
      }
    }
  },
  methods: {
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    checkTooltip (order) {
      return common.checkTooltip(order)
    },
    handleSearch (search) {
      if (search[0] === '1' || (search[0] === '3' && search[1] === '1')) {
        this.shipments = []
        this.loadShipments()
      } else if (search[0] === '2' || (search[0] === '3' && search[1] === '2')) {
        this.shipments = []
        this.loadReturns()
      } else if (search[0] === '4') {
        this.shipments = []
        this.loadCashCollections()
      } else {
        this.loadShipments('all')
      }
      this.searchVal = search
    },
    loadShipments (type) {
      this.tableLoader = true
      sendRequest(true, false, this, `api/v1/delivery-orders/?search=${this.searchVal}`, 'get', null, true, 
        (response) => {
          this.selected = []
          this.shipments = response.data.results
          if (type) {
            this.loadReturns(type)
          } else {
            this.tableLoader = false 
          }
        }
      )
    },
    loadReturns (type) {
      this.tableLoader = true
      sendRequest(true, false, this, `api/v1/returns/?search=${this.searchVal}`, 'get', null, true, 
        (response) => {
          this.selected = []
          this.shipments = [...this.shipments, ...response.data.results]
          if (type) {
            this.loadCashCollections()
          } else {
            this.tableLoader = false
          }
        }
      )
    },
    loadCashCollections () {
      this.tableLoader = true
      sendRequest(true, false, this, `api/v1/cash-collections/?search=${this.searchVal}`, 'get', null, true, 
        (response) => {
          this.selected = []
          this.shipments = [...this.shipments, ...response.data.results]
          this.tableLoader = false
        }
      )
    },
    emptyFields () {
      this.selected = []
      this.shipments = []
      this.searchVal = ''
    }
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  components: {
    Print,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    PrintLabelButton
  }
}
</script>

<style scoped>
.vs-dialog {
  max-width: 530px;
}
</style>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
</style>
